import $ from 'jquery'
const $doc = $(document)

function filterCats(event) {
  let el = $(this);
  $('.js-filter').removeClass('active').attr('aria-pressed', 'false');
  el.addClass('active').attr('aria-pressed', 'true');
  let cat = el.attr('data-cat');
  $('.js-project-item:not(.project-item-nav)').attr('disabled', true);
  $('.js-project-item.' + cat +':not(.project-item-nav)').attr('disabled', false);
}

export default function initFilterCats(){
  $doc.on('click', '.js-filter', filterCats )
}
