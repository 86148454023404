import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
export default function initLanding(){
  if ( $('.header__landing').length ) {
    let once = false;
    $('html').addClass('homepage');
    $(window).scroll(function (event) {
      let landingHeight = $('.header__landing').outerHeight(true);
      let paddingTop = 1500;
      let offset = paddingTop - landingHeight
      let scrollYPosition = $(document).scrollTop();
      let stickyOffset = $('.js-header-wrapper').offset().top;
      if ($(window).width() < 960) {
        if (scrollYPosition >= 1400) {
          $('.header').addClass('sticky');
        } else {
          $('.header').removeClass('sticky');
        }
      } else {
        if (scrollYPosition >= 1380) {
          $('.header').addClass('sticky');
        } else {
          $('.header').removeClass('sticky');
        }
      }
      if (scrollYPosition >= offset) {
        $('.header').addClass('anim-done');
        if (once == false) {
          if ($(window).width() < 960) {
            $([document.documentElement, document.body]).animate({
              scrollTop: 1410
            }, 500);
          } else {
            $([document.documentElement, document.body]).animate({
              scrollTop: 1380
            }, 500);
          }
          setTimeout(() => {
            $('html').addClass('freeze');
          }, 490)
          setTimeout(() => {
            $('html').removeClass('freeze');
          }, 1500)
          once = true;
        }
      } else {
        $('.header').removeClass('anim-done');
        once = false;
      }
    });
  }
}
