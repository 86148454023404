import $ from 'jquery'

const $doc = $(document)

function toggleAccordionItem() {
  let el = $(this);
  el.parent().parent().siblings().find('.accordion__single-item-title button').attr('aria-expanded', 'false');
  el.parent().parent().siblings().find('.accordion__single-item-content').slideUp('fast');
  el.parent().parent().siblings().removeClass('active');
  if ( el.parent().parent().hasClass('active') ) {
    el.attr('aria-expanded', 'false');
  } else {
    el.attr('aria-expanded', 'true');
  }
  el.parent().next().slideToggle('fast');
  el.parent().parent().toggleClass('active');
  if ($(window).width() < 960) {
    $([document.documentElement, document.body]).animate({
      scrollTop: el.parent().parent().offset().top - 140
    }, 300);
  } else {
    $([document.documentElement, document.body]).animate({
      scrollTop: el.parent().parent().offset().top - 170
    }, 300);
  }
}

function toggleAccordionItemLg() {
  let el = $(this);
  el.parent().parent().parent().parent().parent().siblings().find('.js-accordion-item-lg button').attr('aria-expanded', 'false');
  el.parent().parent().parent().parent().parent().siblings().find('.js-accordion-item-lg .building-block__body').slideUp('fast');
  el.parent().parent().parent().parent().parent().siblings().find('.js-accordion-item-lg').removeClass('active');
  if (el.parent().parent().parent().hasClass('active')) {
    el.attr('aria-expanded', 'false');
  } else {
    el.attr('aria-expanded', 'true');
  }
  el.parent().parent().next().slideToggle('fast');
  el.parent().parent().parent().toggleClass('active');
  if ($(window).width() < 960) {
    $([document.documentElement, document.body]).animate({
      scrollTop: el.parent().parent().parent().offset().top - 140
    }, 300);
  } else {
    $([document.documentElement, document.body]).animate({
      scrollTop: el.parent().parent().parent().offset().top - 170
    }, 300);
  }
}

export default function initAccordion(){
  $doc.on('click', '.js-accordion-item button', toggleAccordionItem )
  $doc.on('click', '.js-accordion-item-lg button', toggleAccordionItemLg)
}
