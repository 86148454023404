import 'lazysizes'
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks'
import 'slick-carousel';

import $ from 'jquery'
import initStylebook from '/lib/stylebook'
import initGreenSock from '/lib/greensock'
import initHeadroom from '/lib/headroom'
import initMobileNav from '/lib/mobile-nav'
import initCookies from '/lib/cookies'
import initMagnificPopup from '/lib/magnificPopup'
import initAccordion from '/lib/accordion'
import initTabs from '/lib/tabs'
import initImageGallery from '/lib/image-gallery'
import initTestimonials from '/lib/testimonials'
import initA11yFunctions from '/lib/accessibility'
import initSlider from '/lib/slider'
import initLanding from './lib/landing'
import initFilterCats from './lib/cat-filters'
import initExpandContent from './lib/expand-content'

initMagnificPopup();
initStylebook();
initGreenSock()
initHeadroom()
initLanding()
initMobileNav()
initCookies()
initAccordion()
initTabs()
initImageGallery()
initTestimonials()
initA11yFunctions()
initSlider()
initFilterCats()
initExpandContent()