import $ from 'jquery'
const $doc = $(document)

function expandContent(event) {
  let el = $(this);
  let item = el.parent().parent().parent().parent();
  item.toggleClass('panel-active');
  if ( item.hasClass('panel-active') ) {
    el.attr('aria-expanded', 'true');
    item.find('.home__featured-item-content').attr('aria-hidden', 'false');
    item.find('.button--arrow').attr('tabindex', '0');
  } else {
    el.attr('aria-expanded', 'false');
    item.find('.home__featured-item-content').attr('aria-hidden', 'true');
    item.find('.button--arrow').attr('tabindex', '-1');
  }
}

export default function initExpandContent(){
  $doc.on('click', '.js-expand-featured', expandContent )
}
