import $ from 'jquery'

const $doc = $(document)

function toggleTabItem() {
  let el = $(this);
  let controls = el.attr('aria-controls')

  $('.js-project-item').attr('aria-selected', 'false').attr('tabindex', '-1')
  $('.js-project-featured').attr('hidden', 'true')

  el.attr('aria-selected', 'true').attr('tabindex', '0')
  $(".js-project-item[aria-controls='" + controls + "']").attr('aria-selected', 'true').attr('tabindex', '0');
  $(`#${controls}`).removeAttr('hidden')
  if ($(window).width() < 960) {
    $([document.documentElement, document.body]).animate({
      scrollTop: $(`#${controls}`).offset().top - 90
    }, 300);
  } else {
    $([document.documentElement, document.body]).animate({
      scrollTop: $(`#${controls}`).offset().top - 120
    }, 300);
  }
}

function filmNav() {
  let el = $(this);
  let controls = el.attr('aria-controls')
  let url = '/?featured='+controls;

  window.location.href = url;
}

function logoClick(e) {
  e.preventDefault();
  if ($(window).width() < 960) {
    $([document.documentElement, document.body]).animate({
      scrollTop: $('#featured').offset().top - 90
    }, 300);
  } else {
    $([document.documentElement, document.body]).animate({
      scrollTop: $('#featured').offset().top - 120
    }, 300);
  }
}

export default function initTabs(){
  if ($('body').hasClass('home')) {
    $doc.on('click', '.js-project-item', toggleTabItem)
    let params = (new URL(document.location)).searchParams;
    let hash = window.location.hash;
    if (params.has('featured') ) {
      let featured = params.get('featured');
      $('.js-project-item').attr('aria-selected', 'false').attr('tabindex', '-1')
      $('.js-project-featured').attr('hidden', 'true')
      $(".js-project-item[aria-controls='" + featured + "']").attr('aria-selected', 'true').attr('tabindex', '0');
      $(`#${featured}`).removeAttr('hidden')
      if ($(window).width() < 960) {
        $([document.documentElement, document.body]).animate({
          scrollTop: $(`#${featured}`).offset().top - 90
        }, 300);
      } else {
        $([document.documentElement, document.body]).animate({
          scrollTop: $(`#${featured}`).offset().top - 120
        }, 300);
      }
    }
    if (hash == '#featured' ) {
      if ($(window).width() < 960) {
        $([document.documentElement, document.body]).animate({
          scrollTop: $('#featured').offset().top - 90
        }, 300);
      } else {
        $([document.documentElement, document.body]).animate({
          scrollTop: $('#featured').offset().top - 120
        }, 300);
      }
    }
    $doc.on('click', 'a.logo', logoClick)
  } else {
    $doc.on('click', '.js-project-item', filmNav)
  }
  $( '[role="tablist"]' ).each(function() {
    let tabList = $(this);
    let tabs = tabList.find('[role="tab"]');
    let tabFocus = 0;

    tabList.keydown(function(e) {
      // Move right
      if (e.keyCode === 39 || e.keyCode === 37) {
        tabs[tabFocus].setAttribute('tabindex', -1);
        if (e.keyCode === 39) {
          tabFocus++;
          // If we're at the end, go to the start
          if (tabFocus >= tabs.length) {
            tabFocus = 0;
          }
          // Move left
        } else if (e.keyCode === 37) {
          tabFocus--;
          // If we're at the start, move to the end
          if (tabFocus < 0) {
            tabFocus = tabs.length - 1;
          }
        }

        tabs[tabFocus].setAttribute('tabindex', 0);
        tabs[tabFocus].focus();
      }
    });
  });
}
